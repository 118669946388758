// src/components/AboutUs.js

import React from "react";
import { StaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import SectionTitle from "./sectiontitle"; // Assuming you have a SectionTitle component
// import "../style/aboutus.less"; // Create a corresponding LESS/CSS file for styling

const AboutUs = () => (
    <StaticQuery
        query={graphql`
                  query {
                      aboutMarkdown: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/about.md$/" } }
              ) {
                edges {
                  node {
                    frontmatter {
                      title
                      content
                    }
                    html
                  }
                }
              }
        }
    `}
        render={data => {
            const edges = data.aboutMarkdown.edges;
            const { title, content, image } = edges[0].node.frontmatter;

            return (
                <section id="about" className="container">
                    <div className="partner-row" style={{backgroundColor: 'white'}}>
                        <div className="partner-title">
                            <h4><u>{title || "ABOUT US"}</u></h4>
                        </div>
                        <div className="row">
                            {image && (
                                <div className="col s12 m4">
                                    <img
                                        src={image}
                                        alt="About Us Image"
                                        className="about-us-image"
                                    />
                                </div>
                            )}
                            <div className={`col s12 ${image ? "m8" : "m12"}`}>
                                <ReactMarkdown>{content}</ReactMarkdown>
                                {/*<div*/}
                                {/*    className="about-us-content"*/}
                                {/*    dangerouslySetInnerHTML={{ __html: content }}*/}
                                {/*/>*/}
                            </div>
                        </div>
                    </div>
                </section>
            );
        }}
    />
);

export default AboutUs;
